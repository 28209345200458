@import "icons/categories-icon";


/********** s menu N1 **************/
.hook-top li.li-niveau1 a.a-niveau1::before {
    font-family: 'categories-icon' !important;
    font-size: 30px;
    color: #fff;
    display: block;
    margin: 5px auto 10px auto;
    font-weight: normal;
    text-align: center;
}
.advtm_menu_32 a.a-niveau1 {
    @extend .categories-icon-fruit;
}
.advtm_menu_21 a.a-niveau1 {
    @extend .categories-icon-legumes;
}
.advtm_menu_22 a.a-niveau1 {
    @extend .categories-icon-specialite;
}
.advtm_menu_24 a.a-niveau1 {
    @extend .categories-icon-viande;
}
.advtm_menu_26 a.a-niveau1 {
    @extend .categories-icon-charcuterie;
}
.advtm_menu_25 a.a-niveau1 {
    @extend .categories-icon-poisson;
}
.advtm_menu_29 a.a-niveau1 {
    @extend .categories-icon-lait;
}
.advtm_menu_28 a.a-niveau1 {
    @extend .categories-icon-vin;
}
.advtm_menu_27 a.a-niveau1 {
    @extend .categories-icon-traiteur;
}
.advtm_menu_30 a.a-niveau1 {
    @extend .categories-icon-region;
}
.advtm_menu_31 a.a-niveau1 {
    @extend .categories-icon-monde;
}