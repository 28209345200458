@font-face {
    font-family: "categories-icon";
    src: url('../icons/categories-icon.eot');
    src: url('../icons/categories-icon.eot?#iefix') format('eot'),
      url('../icons/categories-icon.woff2') format('woff2'),
      url('../icons/categories-icon.woff') format('woff'),
      url('../icons/categories-icon.ttf') format('truetype'),
      url('../icons/categories-icon.svg#categories-icon') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .categories-icon{
    display:inline-block;
  }
  .categories-icon:before {
    display: inline-block;
    font-family: "categories-icon";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .categories-icon-lg {
    font-size: 1.3333333333333333em;
  }
  .categories-icon-2x { font-size: 2em; }
  .categories-icon-3x { font-size: 3em; }
  .categories-icon-4x { font-size: 4em; }
  .categories-icon-5x { font-size: 5em; }
  .icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
  }
  
  .categories-icon-ab:before { content: "\E001" }
  .categories-icon-agripousse:before { content: "\E002" }
  .categories-icon-aoc:before { content: "\E003" }
  .categories-icon-bbc:before { content: "\E004" }
  .categories-icon-cereale:before { content: "\E005" }
  .categories-icon-champignon:before { content: "\E006" }
  .categories-icon-charcuterie:before { content: "\E007" }
  .categories-icon-confiture:before { content: "\E008" }
  .categories-icon-fruit:before { content: "\E009" }
  .categories-icon-herbes:before { content: "\E00A" }
  .categories-icon-igp:before { content: "\E00B" }
  .categories-icon-label-rouge:before { content: "\E00C" }
  .categories-icon-lait:before { content: "\E00D" }
  .categories-icon-legumes:before { content: "\E00E" }
  .categories-icon-monde:before { content: "\E00F" }
  .categories-icon-pain:before { content: "\E010" }
  .categories-icon-panier-fruit:before { content: "\E011" }
  .categories-icon-poisson:before { content: "\E012" }
  .categories-icon-processus:before { content: "\E013" }
  .categories-icon-projet:before { content: "\E014" }
  .categories-icon-region:before { content: "\E015" }
  .categories-icon-securite:before { content: "\E016" }
  .categories-icon-specialite:before { content: "\E017" }
  .categories-icon-stg:before { content: "\E018" }
  .categories-icon-traiteur:before { content: "\E019" }
  .categories-icon-viande:before { content: "\E01A" }
  .categories-icon-vin:before { content: "\E01B" }
  .categories-icon-volaille:before { content: "\E01C" }
  